<template>
  <div class="money-log-view">
    <div v-if="!$store.state.isMobileLayout" class="types">
      <div class="type-title">交易类型</div>
      <div
        v-for="(type, idx) in displayTypeList"
        :key="idx"
        :class="[
          'type-btn btn shadow',
          { active: operation_type === type.operation_type },
        ]"
        @click="changeType(type.operation_type)"
        v-html="type.text"
      ></div>
    </div>
    <div
      :class="[$store.state.isMobileLayout ? 'full-content' : 'right-content']"
    >
      <div class="top flex-ac flex-jb">
        <month-picker @setDate="setDate"></month-picker>
        <div v-if="$store.state.isMobileLayout" class="control relative">
          <div
            class="btn type-btn active"
            @click="is_show_option = !is_show_option"
          >
            交易类型
          </div>
          <transition name="fade-up">
            <div v-if="is_show_option" class="option-modal">
              <div class="label">请&nbsp;选&nbsp;择</div>
              <div
                v-for="(type, idx) in displayTypeList"
                :key="idx"
                :class="[
                  'option',
                  { active: operation_type === type.operation_type },
                ]"
                :value="type.operation_type"
                @click="changeType(type.operation_type)"
                v-html="type.text"
              ></div>
            </div>
          </transition>
        </div>
      </div>
      <a-spin :spinning="loading">
        <div v-if="list.length > 0" class="table">
          <div
            v-for="(item, idx) in list"
            :key="idx"
            class="table-row flex-ac flex-jb log-item"
          >
            <div
              :class="['record', item.operation_type === '0' ? 'red' : 'green']"
            >
              <p v-text="item.type_name"></p>
              <p v-text="item.money"></p>
            </div>
            <div v-text="item.record_date"></div>
          </div>
        </div>
        <div v-else class="no-data">暫無數據</div>
        <div class="bottom">
          <!--<div v-if="showPagination" class="pages flex-ac flex-jb">
            <div class="page-btns flex">
              <div class="page-btn" @click="firstPage">首页</div>
              <div class="page-btn" @click="prevPage">上一页</div>
              <div class="page-btn" @click="nextPage">下一页</div>
              <div class="page-btn" @click="lastPage">末页</div>
            </div>
            <div class="page-index">
              第 {{ pages }} 页 共 {{ totalPages }} 页
            </div>
          </div>-->
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { post } from '@/axios'
import MonthPicker from '@/components/month-picker.vue'
import pagination from '@/mixins/pagination'

export default {
  name: 'money_log',
  mixins: [pagination],
  components: {
    //MonthPicker
  },
  data() {
    return {
      loading: true,
      operation_type: '',
      is_show_option: false,
      displayTypeList: [
        {
          text: '全部',
          operation_type: ''
        }
      ],
      typeList: [
        {
          text: '全部',
          operation_type: ''
        },
        {
          text: '充值',
          operation_type: '0'
        },
        {
          text: '提现',
          operation_type: '1'
        }
      ],
      month_list: [],
      list: [],
      show_pages: false
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    changeType(val) {
      this.operation_type = val
      this.is_show_option = false
      this.pages = 1
    },
    async getData() {
      const mapStr = JSON.stringify({
        pages: this.pages,
        rows: 5,
        operation_type: this.operation_type
      })

      try {
        this.loading = true
        const { code, info, data } = await post(
          'record_capitalRecoed.do',
          this.Qs.stringify({ mapStr })
        )
        if (code === 'fail') {
          this.$message.error(info, 2)
        } else {
          const { capitalList, total_num } = JSON.parse(data)
          this.list = capitalList
          this.total_num = total_num
        }
      } catch {
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    pages() {
      this.getData()
    },
    monthDate() {
      this.getData()
    },
    operation_type() {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';
@import '~@/assets/style/variables.scss';

.money-log-view {
  padding: 2rem 2%;
}

.option-modal {
  position: absolute;
  width: 100%;
  top: 2.2rem;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  min-width: 4rem;

  .label {
    color: #999;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    padding: 0.5rem;
  }

  .option {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1rem;
    cursor: pointer;

    &.active {
      color: $theme-color;
    }
  }
}

.select {
  background: transparent;
  color: white;
  border: none;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;

  > option {
    color: black;
    text-align: center;
  }
}

.top {
  width: 100%;
}

.table {
  width: 100%;
}

.types {
  width: 8rem;
  display: flex;
  flex-direction: column;
}

.type-title {
  color: white;
  margin: 0.5rem 0 1rem;
  font-size: 1rem;
}

.type-btn {
  color: white;
  margin-bottom: 0.8rem;
  cursor: pointer;
  letter-spacing: 10px;
}

.right-content {
  flex: 1;
  margin-left: 0.5rem;
}

.table-row {
  width: 100%;
  font-size: 0.8rem;
  margin-bottom: 1px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &:last-child {
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
  }
}

.bottom {
  width: 100%;
  height: 6rem;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.hint {
  width: 50%;
  margin: 0 auto 1rem;
  line-height: 1.5;
  font-size: 0.8rem;
  color: #999;
  text-align: left;
}

.pages {
  position: relative;
  font-size: 0.9rem;
  user-select: none;
}

.page-btns {
  width: 100%;
}

.page-btn {
  color: white;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #c9a67c;
  }
}

.page-index {
  width: 20%;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

@include md {
  .select {
    font-size: 0.8rem;
  }

  .full-content {
    width: 100%;
  }

  .btn.type-btn {
    letter-spacing: 0.1rem;
  }

  .option-modal {
    border-radius: 5px;
    z-index: 1;

    .label {
      padding: 0;
      font-size: 0.7rem;
    }

    .option {
      font-size: 0.8rem;
    }
  }

  .table-row {
    background-color: #313131;
    color: #999;
    padding: 0.5rem;

    &:hover {
      background-color: #1d1d1d;
      color: white;
    }
  }

  .row-record {
    font-size: 0.7rem;
  }

  .bottom {
    width: 100%;
    position: relative;
    margin-top: 1rem;

    .hint {
      width: 100%;
    }
  }

  .pages {
    font-size: 0.8rem;
    display: block;
  }

  .page-btns {
    justify-content: center;
  }

  .page-index {
    width: 100%;
    margin-top: 0.5rem;
    position: relative;
    text-align: center;
  }
}
</style>
